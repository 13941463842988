import { EditType } from 'types';
import { SearchWorkerResult } from 'components/searchBox/types';
import {
  EditableJobsiteFormSubmissionSelectableWorker,
  EditableJobsiteFormSubmissionWorker,
} from 'containers/jobsiteFormSubmission/types';

export type OnWorkerConfirmed = (jfsWorker: EditableJobsiteFormSubmissionWorker, editType: EditType) => void;

export type JobsiteFormSubmissionWorkerEdit = EditableJobsiteFormSubmissionWorker & {
  editType: EditType;
  searchWorkerResult: SearchWorkerResult;
};

export type FormSubmissionWorkersProps = {
  jobsiteFormSubmissionId?: string;
  associationType?: string;
  value?: EditableJobsiteFormSubmissionSelectableWorker[];
  onChange?: (value: EditableJobsiteFormSubmissionSelectableWorker[]) => void;
};

export enum ParticipantType {
  Injured = 'Injured',
  Other = 'Other',
  Responsible = 'Responsible',
  Suspected = 'Suspected',
  Witness = 'Witness',
}
