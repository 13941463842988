import { SearchWorkerResult } from 'components/searchBox/types';
import { JobsiteFormSubmissionWorkerEdit } from 'containers/jobsiteFormSubmission/components/formSubmissionWorkers/types';
import { EditableJobsiteFormSubmissionWorker } from 'containers/jobsiteFormSubmission/types';
import { getFormattedPhoneNumber } from 'utils';

const getSearchWorkerResult = (jfsWorker: EditableJobsiteFormSubmissionWorker): SearchWorkerResult => {
  const { jobsitesInfo } = jfsWorker;
  const { jobsiteWorkerId, contractorWorker } = jfsWorker.jobsiteWorker ?? {};
  const { worker } = contractorWorker ?? {};
  const { firstName, lastName, email, phoneNumber } = worker?.user.identity ?? {};

  const jobsiteWorker =
    jobsitesInfo?.find((jw) => jw.jobsiteWorkerId === jobsiteWorkerId)?.jobsiteWorker ??
    jobsitesInfo?.[0]?.jobsiteWorker;

  return {
    id: worker.workerId,
    firstName,
    lastName,
    email,
    phone: getFormattedPhoneNumber(phoneNumber),
    quickCode: worker.quickCode,
    badgeId: jobsiteWorker?.stickerNumber?.toString(),
    imageUrl: worker.profilePicture?.downloadUrl,
    trade: worker.trade,
    tradeClass: worker.jobTitle,
    unionAffiliation: worker.unionAffiliation,
    jobsitesInfo,
    searchTerm: null,
  };
};

export const getInitialJfsWorkerEdit = (
  jfsWorker?: EditableJobsiteFormSubmissionWorker,
): JobsiteFormSubmissionWorkerEdit =>
  jfsWorker ? { ...jfsWorker, editType: 'update', searchWorkerResult: getSearchWorkerResult(jfsWorker) } : null;
