import React from 'react';
import moment, { MomentInput } from 'moment';
import { useLocation } from 'react-router';
import { EditableJobsiteFormSubmissionSelectableWorker, JobsiteWorker } from 'containers/jobsiteFormSubmission/types';
import { useGetAvailableJobsiteWorkersQuery } from 'apollo/generated/client-operations';
import { usePageQueryParams } from 'utils/usePageQueryParams';
import { Filter, NewButton, getFaIcon } from '@odin-labs/components';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FormSubmissionSelectableWorker } from './FormSubmissionSelectableWorker';
import {
  WorkerFiltersOptions,
  WorkersFilters,
  getFilterItems,
  getFiltersOptions,
} from './FormSubmissionSelectableWorkers.tables';

export type FormSubmissionSelectableWorkersProps = {
  jobsiteId?: string;
  contractorIds?: string[];
  date?: MomentInput;
  associationType?: string;
  allowMultipleContractors?: boolean;
  value?: EditableJobsiteFormSubmissionSelectableWorker[];
  onChange?: (value: EditableJobsiteFormSubmissionSelectableWorker[]) => void;
};

const CheckIcon = getFaIcon({ icon: faCheck });

export const FormSubmissionSelectableWorkers = React.forwardRef<HTMLDivElement, FormSubmissionSelectableWorkersProps>(
  (props, ref): React.ReactElement => {
    const { value, onChange, jobsiteId, contractorIds, date, associationType, allowMultipleContractors } = props;
    const location = useLocation();

    const {
      search,
      currentlyOnsite,
      trades,
      tradeClasses,
      // orderBy: defaultSortField,
      // orderByDesc: isDescending,
      updateUrl,
      loading: isUrlLoading,
    } = usePageQueryParams();

    const { data, loading } = useGetAvailableJobsiteWorkersQuery({
      fetchPolicy: 'no-cache',
      skip: !jobsiteId || isUrlLoading,
      variables: {
        jobsiteId,
        jobsiteJobsiteWorkersInput: {
          contractorIds,
          search,
          onSite: currentlyOnsite ? currentlyOnsite.toLowerCase() === 'true' : null,
          onSiteDate: moment.utc(date).toDate(),
          trades,
          tradeClasses,
        },
      },
    });
    const jobsiteWorkers = data?.getJobsite.jobsiteWorkers.edges.map(({ node }) => node);

    const filtersOptions = React.useMemo<WorkerFiltersOptions>(
      () => ({
        trades: getFiltersOptions(jobsiteWorkers?.map((jw) => jw.contractorWorker.worker.trade)),
        tradeClasses: getFiltersOptions(jobsiteWorkers?.map((jw) => jw.contractorWorker.worker.jobTitle)),
      }),
      [jobsiteWorkers],
    );

    const filterItems = React.useMemo(
      () => getFilterItems({ filtersOptions, search, currentlyOnsite, trades, tradeClasses }),
      [filtersOptions, search, currentlyOnsite, trades, tradeClasses],
    );

    const onFilterChangeHandler = (changedFilters: Partial<WorkersFilters>): void => {
      updateUrl({ page: null, ...changedFilters }, true, location?.hash);
    };

    const toggleItem = React.useCallback(
      (jobsiteWorker: JobsiteWorker): void => {
        const { jobsiteWorkerId } = jobsiteWorker;
        const submissionWorker = value?.find((sw) => sw.jobsiteWorker.jobsiteWorkerId === jobsiteWorkerId);
        let newValue: EditableJobsiteFormSubmissionSelectableWorker[];
        if (submissionWorker) {
          // if worker has been previously removed then it will be updated
          if (submissionWorker?.changeType === 'removed') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            newValue = value.map((sw) => {
              // the worker gets restored if previously removed
              if (sw.id === submissionWorker.id) {
                const { changeType, ...newSw } = sw; // eslint-disable-line @typescript-eslint/no-unused-vars
                return newSw;
              }
              return sw;
            });
          } else {
            newValue =
              submissionWorker.changeType === 'created'
                ? value.filter((sw) => sw !== submissionWorker)
                : value.map((sw) => (sw.id !== submissionWorker.id ? sw : { ...sw, changeType: 'removed' }));
          }
        } else {
          // add new worker
          newValue = [...(value ?? []), { jobsiteWorker, id: undefined, changeType: 'created', associationType }];
        }

        if (newValue) {
          onChange?.(newValue);
        }
      },
      [onChange, value],
    );

    const selectedJobsiteWorkerIds = value
      ?.filter((sw) => sw.changeType !== 'removed')
      .map((sw) => sw.jobsiteWorker.jobsiteWorkerId);
    const areAllSelected =
      jobsiteWorkers?.length && jobsiteWorkers.every((jw) => selectedJobsiteWorkerIds?.includes(jw.jobsiteWorkerId));

    const selectAllClickHandler = React.useCallback(() => {
      if (jobsiteWorkers?.some((jw) => !selectedJobsiteWorkerIds?.includes(jw.jobsiteWorkerId))) {
        const newValue = jobsiteWorkers.reduce<EditableJobsiteFormSubmissionSelectableWorker[]>(
          (result, jobsiteWorker) => {
            const { jobsiteWorkerId } = jobsiteWorker;
            const submissionWorker = value?.find((sw) => sw.jobsiteWorker.jobsiteWorkerId === jobsiteWorkerId);
            if (submissionWorker) {
              // the worker gets restored if previously removed
              if (submissionWorker.changeType === 'removed') {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { changeType, ...newSubmissionWorker } = submissionWorker;
                result.push(newSubmissionWorker);
              } else {
                result.push(submissionWorker);
              }
            } else {
              // add new worker
              result.push({ jobsiteWorker, id: undefined, changeType: 'created', associationType });
            }
            return result;
          },
          [],
        );
        onChange?.(newValue);
      } else if (areAllSelected) {
        const newValue = jobsiteWorkers.reduce<EditableJobsiteFormSubmissionSelectableWorker[]>(
          (result, jobsiteWorker) => {
            const { jobsiteWorkerId } = jobsiteWorker;
            const submissionWorker = value?.find((sw) => sw.jobsiteWorker.jobsiteWorkerId === jobsiteWorkerId);
            if (submissionWorker) {
              if (!submissionWorker.changeType) {
                result.push({ ...submissionWorker, changeType: 'removed' });
              } else if (submissionWorker.changeType !== 'created') {
                result.push(submissionWorker);
              }
            }
            return result;
          },
          [],
        );
        onChange?.(newValue);
      }
    }, [onChange, value, jobsiteWorkers, selectedJobsiteWorkerIds, areAllSelected]);

    return (
      <div className="odin-flex odin-flex-col odin-gap-y-4.5">
        <Filter
          items={filterItems}
          loading={loading}
          firstItemOnRight="search"
          onChange={onFilterChangeHandler}
          leftContainerStyle="sm:odin-pr-2.5"
        >
          <NewButton
            theme={areAllSelected ? 'secondary' : 'white'}
            size="xs"
            text={areAllSelected ? 'Unselect All' : 'Select All'}
            icon={CheckIcon}
            onClick={selectAllClickHandler}
          />
        </Filter>
        <div
          ref={ref}
          className={`odin-flex odin-flex-col odin-gap-3 
        sm:odin-grid sm:odin-grid-cols-2 lg:odin-grid-cols-4`}
        >
          {jobsiteWorkers?.map((jobsiteWorker) => (
            <FormSubmissionSelectableWorker
              key={jobsiteWorker.jobsiteWorkerId}
              jobsiteWorker={jobsiteWorker}
              isSelected={value?.some(
                (sw) =>
                  sw.jobsiteWorker.jobsiteWorkerId === jobsiteWorker.jobsiteWorkerId && sw.changeType !== 'removed',
              )}
              onClick={(): void => toggleItem(jobsiteWorker)}
              showContractor={allowMultipleContractors}
            />
          ))}
        </div>
      </div>
    );
  },
);
