import React from 'react';
import moment, { Moment } from 'moment';
import { DeepMap } from 'react-hook-form';
import {
  FormData,
  FormInput,
  TypedFormInputs,
  UseFormMethods,
  UseInputs,
  getUpdateInputValueFunction,
} from 'components/form';
import {
  JobsiteFormSubmission,
  EditFormData,
  FormContext,
  JobsiteFormSubmissionWorker,
  FormSubmissionTabDefinition,
  WatchedFieldConfig,
  CustomFormInputs,
  ContextDefaultSelectOptions,
  ContextSelectOptions,
  FormDynamicContext,
} from 'containers/jobsiteFormSubmission/types';
import {
  createAvailableJobsiteWorkerOptions,
  formInputsAsArray,
  getFeaturesModule,
  useWatchedFields,
} from 'containers/jobsiteFormSubmission/utils';
import { JobsiteUpdateFormSubmissionInput } from 'apollo/generated/client-operations';
import { AuthUser } from 'acl';
import { ensureNonUndefinedFields, evalJsCode, isEmpty } from 'utils';
import { getDateTime } from 'utils/dates';
import { getDocumentsUpdateInput, getJobsiteWorkersUpdateInput } from './forms';
import { DocumentsSectionData } from './forms/documents';

export type GetFormInputsArgs = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  defaultValues: EditFormData;
  evalContext: EvalContext<EditFormData | FormData>;
  setTabsDefinition: (tabs: FormSubmissionTabDefinition) => void;
};

export const getContextSelectOptions = <T extends ContextDefaultSelectOptions>(options: T): ContextSelectOptions => {
  return options as unknown as ContextSelectOptions;
};

type GetEvalContextArgs<TFormData extends EditFormData | FormData> = {
  user: AuthUser;
  jobsiteFormSubmission: JobsiteFormSubmission;
  dependencies: Record<string, unknown>;
  edit?: CustomFormInputs<TFormData>;
  fn?: Partial<FormContext<TFormData>['fn']>;
  form?: UseFormMethods<TFormData>;
  options?: FormContext<TFormData>['options'];
  dynCtx?: FormDynamicContext<TFormData>;
};

export type EvalContext<TFormData extends EditFormData | FormData> = {
  ctx: FormContext<TFormData>;
};

export const getEvalContext = <TFormData extends EditFormData | FormData>(
  args: GetEvalContextArgs<TFormData>,
): EvalContext<TFormData> => {
  const {
    user,
    jobsiteFormSubmission,
    edit = {} as CustomFormInputs<TFormData>,
    fn,
    dependencies,
    form,
    options,
    dynCtx,
  } = args;
  const { jobsiteForm, jobsiteContractors, jobsiteWorkers, extraData } = jobsiteFormSubmission ?? {};
  const { getDateTimeUpdateInput } = fn ?? {};
  const features = getFeaturesModule(jobsiteForm?.jobsite.modules);
  const availableJobsiteWorkerOptions = options?.availableJobsiteWorkers ?? createAvailableJobsiteWorkerOptions();

  return {
    ctx: {
      dependencies,
      fn: {
        ...fn,
        getDateTimeUpdateInput,
        ...dynCtx?.fn,
      },
      user,
      data: {
        ...jobsiteFormSubmission,
        extraData: extraData ?? {},
        jobsiteId: jobsiteForm?.jobsite.jobsiteId,
        contractorIds:
          jobsiteContractors?.edges.map(({ node }) => node.jobsiteContractor.contractor.contractorId) ?? [],
        jobsiteWorkerIds: jobsiteWorkers?.edges.map(({ node }) => node.jobsiteWorker.jobsiteWorkerId) ?? [],
        formSubmissionWorkersByAssociationType:
          jobsiteWorkers?.edges.reduce((result, { node }) => {
            if (!result[node.associationType]) Object.assign(result, { [node.associationType]: [] });
            result[node.associationType].push(node);
            return result;
          }, {} as Record<string, JobsiteFormSubmissionWorker[]>) ?? {},
      },
      edit,
      form,
      features,
      options: getContextSelectOptions({
        ...options,
        availableJobsiteWorkers: availableJobsiteWorkerOptions,
        ...dynCtx?.options,
      }),
    },
  };
};

export const getFormInputsHook =
  (args: GetFormInputsArgs): UseInputs<EditFormData> =>
  (form: UseFormMethods<EditFormData>): FormInput<EditFormData>[] => {
    const { watch, getValues } = form;
    const { jobsiteFormSubmission, defaultValues, evalContext: baseEvalContext, setTabsDefinition } = args;
    const {
      inputs: inputsExpression,
      tabs: tabsExpression,
      watchedFields: watchedFieldsConfig,
    } = jobsiteFormSubmission?.jobsiteForm.form.content.edit ?? {};

    const watchedFields = (watchedFieldsConfig as WatchedFieldConfig<CustomFormInputs<EditFormData>>[]).map((item) =>
      typeof item === 'string' ? item : item.field,
    );
    const watched = watch(watchedFields) as CustomFormInputs<EditFormData>;
    const state = { ...defaultValues, ...getValues(), ...watched };

    const evalContext = React.useMemo(() => {
      return baseEvalContext && { ctx: { ...baseEvalContext.ctx, edit: state, form } };
    }, [baseEvalContext, JSON.stringify(state), form]);

    const { inputs, tabsBadges } = React.useMemo(() => {
      const computedInputs = evalContext && evalJsCode<TypedFormInputs<EditFormData>>(inputsExpression, evalContext);
      const computedTabsBadges = evalContext && evalJsCode<FormSubmissionTabDefinition>(tabsExpression, evalContext);
      return { inputs: formInputsAsArray(computedInputs), tabsBadges: computedTabsBadges };
    }, [inputsExpression, tabsExpression, evalContext]);

    React.useEffect(() => {
      if (!isEmpty(tabsBadges)) setTabsDefinition(tabsBadges);
    }, [JSON.stringify(tabsBadges)]);

    useWatchedFields(watchedFieldsConfig, watched, evalContext);

    return inputs;
  };

type GetDefaultValuesArgs = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  evalContext: EvalContext<EditFormData | FormData>;
};

export const getDefaultValues = (args: GetDefaultValuesArgs): EditFormData => {
  const { jobsiteFormSubmission, evalContext } = args;
  const { defaultValues: defaultValuesExpression } = jobsiteFormSubmission?.jobsiteForm.form.content.edit ?? {};

  return evalContext && evalJsCode(defaultValuesExpression, evalContext);
};

export type GetJobsiteFormSubmissionUpdateInputArgs = {
  jobsiteFormSubmission: JobsiteFormSubmission;
  evalContext: EvalContext<EditFormData | FormData>;
  updateInputsExpression: string;
  extraDataFields: string[];
  defaultValues: CustomFormInputs<EditFormData>;
  data: CustomFormInputs<EditFormData>;
  dirtyFields: DeepMap<CustomFormInputs<EditFormData>, true>;
};

export const getJobsiteFormSubmissionUpdateInput = async ({
  jobsiteFormSubmission,
  evalContext: baseEvalContext,
  updateInputsExpression,
  extraDataFields,
  defaultValues,
  data,
  dirtyFields,
}: GetJobsiteFormSubmissionUpdateInputArgs): Promise<JobsiteUpdateFormSubmissionInput> => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  /**
   * This function receives `date` and `time` as arguments and returns a date object composed based on the arguments.
   * The returned Date object will be calculated based on the `date` arguments as it follows:
   *  - if `date` is a field name, then the form field value will be used;
   *  - if `date` is a Date, then the passed value will be used.
   * @returns
   */
  const getDateTimeUpdateInput = (args: { date: string | Date | Moment; time: string; timeZone: string }): Date => {
    const { date, time, timeZone } = args;
    const isDateAField = typeof date === 'string' && date in data;
    // get `updDate` only if a field name is specified through date parameter
    const updDate = isDateAField ? getUpdateInputValue(date) : undefined;
    const updTime = getUpdateInputValue(time) as string;
    return updDate || updTime
      ? getDateTime({
          date: updDate ?? moment(isDateAField ? data[date] : date),
          time: updTime ?? (time in data ? (data[time] as string) : time),
          timeZone,
        })
      : undefined;
  };

  const evalContext = { ctx: { ...baseEvalContext.ctx, edit: data, fn: { getDateTimeUpdateInput } } };
  const customUpdateInputs = evalJsCode<JobsiteUpdateFormSubmissionInput>(updateInputsExpression, evalContext);

  const extraDataInput = Object.fromEntries(extraDataFields.map((field) => [field, getUpdateInputValue(field)]));

  const documentsUpdateInput = await getDocumentsUpdateInput(data as DocumentsSectionData, dirtyFields);

  const { form } = jobsiteFormSubmission?.jobsiteForm ?? {};

  return ensureNonUndefinedFields<JobsiteUpdateFormSubmissionInput>({
    id: jobsiteFormSubmission.id,
    extraData: extraDataInput,
    ...customUpdateInputs,
    ...getJobsiteWorkersUpdateInput({ data, dirtyFields, defaultValues, form }),
    ...documentsUpdateInput,
  });
};
